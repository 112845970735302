<template>
    <div class="box-page">
    </div>
</template>
<script>
    export default {
      async mounted() {
        var that = this;
        var token = this.$store.getters.token;
        if (!this.$commonFuction.isEmpty(token)){
          this.$AuthenticationApp.checkToken(token).then(async (res) => {
            if (res){
              //token còn hạn
              console.log("resresres", res);
              that.$router.push({path: that.$constants.URL_ERR.REDIRECT});
            } else {
              that.$router.push({path: that.$constants.URL_ERR.LOGIN});
            }
          })
        } else {
          that.$router.push({path: that.$constants.URL_ERR.LOGIN});
        }
      }
    }
</script>

<style lang="scss" scoped>
.box-page{display: block !important;
}
@media (max-width: 767px){
    .box-img{
        iframe{max-width: 100%;}
    }
}
</style>